import FileSaver from 'file-saver';
import { defineConfig } from '../config'
import { authHeader } from '../helpers';
import { utilsService } from './utils'
const { handleResponse } = utilsService

export const auditService = {
  exportFile,
  importFile,
  create,
  update,
  deleteAudit,
  getAll,
  getPaginatedByRangeDate,
  getDistinctByField,
  updateDetectionNewLead,
  getMediaOwnerToSendMediaSupplier,
  getBrandNameToSendSecretarialData,
  getAllItems,
  getItemById,
  deleteItem,
  createItem,
  updateItem,
  getAllAgentNameAndSurname,
  createAgent,
};

const { apiUrl, apiVer } = defineConfig.AUDIT_SERVICE;

function getApiPath() {
  const version = (apiVer !== null ? ("/" + apiVer) : "");
  return (apiUrl + version);
}

function getAll() {
  const url = getApiPath() + "/detection/all";

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getPaginatedByRangeDate(dateStart, dateEnd, page = "0", perPage = "99999", sortType = "ASC") {
  const url = getApiPath() + `/detection/byRangeDate/page/${page}/perPage/${perPage}/sort/${sortType}/?dateStart=${dateStart}&dateEnd=${dateEnd}`;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getDistinctByField(field) {
  const url = getApiPath() + `/detection/distinct/${field}`;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function exportFile(startDate, endDate) {
  const url = getApiPath() + `/detection/exportExcel?dateStart=${startDate}&dateEnd=${endDate}`;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(response => {
      return response.blob()
    })
    .then(function (blob) {
      FileSaver.saveAs(blob, `export${startDate}${endDate}.xlsx`)
      return true;
    })
}

function importFile(file) {
  const url = getApiPath() + "/detection/upload";
  const formData = new FormData();
  formData.append("file", file);

  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: formData
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function create(obj) {
  const url = getApiPath() + "/detection/save";

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(obj)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function update(id, obj) {
  const url = getApiPath() + `/detection/update?auditId=${id}`;

  const requestOptions = {
    method: "PUT",
    headers: authHeader("application/json"),
    body: JSON.stringify(obj)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function deleteAudit(id) {
  const url = getApiPath() + "/detection/delete?auditId=" + id;

  const requestOptions = {
    method: "DELETE",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function updateDetectionNewLead(id, obj) {
  const url = getApiPath() + `/detection/updateDetectionNewLead/${id}`;

  const requestOptions = {
    method: "PUT",
    headers: authHeader("application/json"),
    body: JSON.stringify(obj)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getMediaOwnerToSendMediaSupplier(mediaOwner) {
  const url = getApiPath() + `/detection/getMediaOwnerToSendMediaSupplier?mediaOwner=${mediaOwner}`;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getBrandNameToSendSecretarialData(brandName) {
  const url = getApiPath() + `/detection/getBrandNameToSendSecretarialData?brandName=${brandName}`;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getAllItems(field) {
  const url = getApiPath() + `/detection/${field.replace("_", "")}/all`;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getItemById(field, id) {
  const url = getApiPath() + `/detection/${field.replace("_", "")}/findById/${id}`;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}


function deleteItem(field, id) {
  const url = getApiPath() + `/detection/${field.replace("_", "")}/delete/${id}`;

  const requestOptions = {
    method: "DELETE",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function createItem(field, fieldValue) {
  const obj = {
    [field]: fieldValue
  };
  const url = getApiPath() + `/detection/${field.replace("_", "")}/save`;

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(obj)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function updateItem(field, obj) {
  const url = getApiPath() + `/detection/${field.replace("_", "")}/update`;

  const requestOptions = {
    method: "PUT",
    headers: authHeader("application/json"),
    body: JSON.stringify(obj)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getAllAgentNameAndSurname() {
  const url = getApiPath() + `/detection/agent/allNameAndSurname`;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function createAgent(agent) {
  
  const url = getApiPath() + `/detection/agent/save`;

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(agent)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}