import React, {Suspense, useState, useEffect} from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import {defineConfig} from './config';
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute.react";
import Loader from './components/Loader/Loader.react'
import {Button, Result} from 'antd';
import {userService} from './services'
import {publicRoutes} from './config/routes';
import { ConfigProvider } from 'antd';

const {
    colorPrimaryBg,
    colorPrimaryBgHover,
    colorPrimaryBorder,
    colorPrimaryBorderHover,
    colorPrimaryHover,  
    colorPrimary,     
    colorPrimaryActive,    
    colorPrimaryTextHover, 
    colorPrimaryText,      
    colorPrimaryTextActive,
    colorTextBase,
    colorBgBase,
    colorBgContainer,
    colorBgElevated,
    bodyBg,
    contentBg,
    colorText,
} = defineConfig;

ConfigProvider.config({
  theme: {
    colorPrimaryBg,
    colorPrimaryBgHover,
    colorPrimaryBorder,
    colorPrimaryBorderHover,
    colorPrimaryHover,  
    colorPrimary,     
    colorPrimaryActive,    
    colorPrimaryTextHover, 
    colorPrimaryText,      
    colorPrimaryTextActive,
    colorTextBase,
    colorBgBase,
    colorBgContainer,
    colorBgElevated,
    bodyBg,
    contentBg,
    colorText
  },
});

const LogoutButton = <>
  <Button color='secondary' size="lg" className='mb-6' onClick={() => window.location.replace("/logout")}>LOGOUT</Button>
</>

const Error403 = (props) => (
  <Result
    status="403"
    title="403"
    subTitle="Ci dispiace, la pagina non esiste"
    extra={LogoutButton}
    {...props}
  />
)
const Error404 = (props) => (
  <Result
    status="404"
    title="404"
    subTitle="Ci dispiace, non sei autorizzato a visualizzare questa pagina"
    extra={<Button type="secondary" href="/">Torna alla Home</Button>}
    {...props}
  />
)

const {routes, ADMIN_ROLE} = defineConfig

const getPageLazy = (path) => React.lazy(() => {
  return Promise.all([
    import(`${path}`),
    new Promise(resolve => setTimeout(resolve, 100))
  ])
  .then(([moduleExports]) => moduleExports);
});



const publicPages = publicRoutes.map(route => {
  return {
    path: route.path,
    Render: getPageLazy(route.component)
  }
})

function App() {
  const [type, setType] = useState(userService.getLocalUserType());

  // Workaround resizeObserver problem
  useEffect(() => {
    window.addEventListener('error', e => {
        if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
            const resizeObserverErrDiv = document.getElementById(
                'webpack-dev-server-client-overlay-div'
            );
            const resizeObserverErr = document.getElementById(
                'webpack-dev-server-client-overlay'
            );
            if (resizeObserverErr) {
                resizeObserverErr.setAttribute('style', 'display: none');
            }
            if (resizeObserverErrDiv) {
                resizeObserverErrDiv.setAttribute('style', 'display: none');
            }
        }
    });
  }, []);

  useEffect( () => {
    setType(userService.getLocalUserType())
    // checkUserMocked()
  }, [])

  const checkUserMocked = () => {
    const user = localStorage.getItem('user')
    if(user && user.principal){
      return
    } else {
      if(window.location!=='/login'){
        userService.logout()
        window.location.assign('/login')
      }
    }
  }

  const routesWithPermission = routes.map(route => {
    if((type === ADMIN_ROLE) || (route.roles && route.roles.find(role => role === type))){
      route.isAllowed = true;
    }
    else{
      route.isAllowed = false;
    }
    return route;
  })

  const setDefaultHomePage = (privPage) => {
    // Si presuppone che ci sia una sola homepage
    const homePage = privPage.find(pp => pp.path === '/')
    if(!homePage.isAllowed) {
      let homeFake = privPage.find(pp => pp.isAllowed)
      if(!homeFake)
        return privPage

      homePage.Render = homeFake.Render
      homePage.isAllowed = true
      privPage = privPage.map(pp => {
        if(pp.path === '/'){
          pp = homePage
        }
        return pp
      })
    }
    return privPage
  }

  var privatePages = routesWithPermission.map(route => {
    return {
      path: route.path,
      isAllowed: route.isAllowed,
      Render: getPageLazy(route.component)
    }
  })

  privatePages = setDefaultHomePage(privatePages);

  return (
    <Suspense fallback={null}>
      <Router>
        <Switch>
          {privatePages.map((Component, index) => <PrivateRoute key={index} exact path={Component.path} isAllowed={Component.isAllowed} render={(props) => <Component.Render {...props}/>} />)}
          {publicPages.map((Component, index) => <Route key={index} exact path={Component.path} render={(props) => <Component.Render {...props} />} />)}
          <Route key="Error-403" exact path={"/403"} render={Error403}/>
          <Route key="Error-404" exact path={"/404"} render={Error404}/>
          <Route component={Error404}/>
        </Switch>
      </Router>
    </Suspense>
  );
}

export default App;



