import {userService} from '../services';

export const utilsService = {
    handleResponse,
    getHomePagePath,
    handleResponseBlob
};

function handleResponse(response) {
    return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        switch(response.status){
          case 401:
             // auto logout if 401 response returned from api
            userService.logout();
            window.location.reload(true);
            break;
          case 409:
            return Promise.resolve({...data, statusCode: 409})
          case 404:
            return Promise.resolve(data)
          default:
        }
        
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
  
      return data;
    });
}

function getHomePagePath(props, user = null) {
  if(user === null)
    return "/logout";

  let pathHomepage = "/"

  const storagedUser = JSON.parse(user);

  if(storagedUser.authorities.some(((auth) => (auth === "ROLE_SALES" || auth === "ROLE_SALESLEADER")))) {
    pathHomepage = "/orders"
  }

  let { from } = props.location.state || {
    from: { pathname: pathHomepage },
  };

  return from
}


function handleResponseBlob(response) {
  return response.blob().then(blob => {
      return blob;
    }
  );
}