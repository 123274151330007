import FileSaver from "file-saver";
import { defineConfig } from "../config";
import { authHeader } from "../helpers";
import { utilsService } from "./utils";
const { handleResponse } = utilsService;

export const estimateService = {
  getAllPaginated,
  getAllDto,
  getAllAgents,
  getAllCustomers,
  getCustomerById,
  getById,
  deleteById,
  downloadPdf,
  save,
  getCommissionsById,
  changeStatus
};

const { apiUrl } = defineConfig.ESTIMATE_SERVICE;

function getAllPaginated(page = "0", perPage = "9999", sortType = "ASC", sortValue="name") {
  const url = apiUrl + `all/page/${page}/perPage/${perPage}/sort/${sortType}/`+"?sortValue=" + sortValue;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getCommissionsById(id) {
  const url = apiUrl + `commission/calculateCommission/${id}`;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function save(estimation, customerId = null, agentId = null) {
  const url = apiUrl + `save?customerId=${customerId}&agentId=${agentId}`;

  const requestOptions = {
    method: "POST",
    headers: authHeader("application/json"),
    body: JSON.stringify(estimation)
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getAllDto() {
  const url = apiUrl + `getAllDto`;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getAllCustomers() {
  const url = apiUrl + `customer/all`;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getAllAgents() {
  const url = apiUrl + `agent/all`;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getCustomerById(id) {
  const url = apiUrl + `customer/${id}`;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getById(id) {
  const url = apiUrl + `${id}`;

  const requestOptions = {
    method: "GET",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function downloadPdf(id, fileName) {
  const url = apiUrl + `${id}/pdf`;

  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    url,
    requestOptions
  )
  .then(response => {
    console.log("scarico....", url)
    return response.blob()
  })
  .then(function(blob) {
    FileSaver.saveAs(blob, fileName)
    return true;
  })
}

function deleteById(id) {
  const url = apiUrl + `delete?id=${id}`;


  const requestOptions = {
    method: "DELETE",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function changeStatus(estimateId, status) {
  const url = apiUrl + `changeStatus?estimateId=${estimateId}&status=${status}`;

  const requestOptions = {
    method: "PUT",
    headers: authHeader("application/json"),
  };

  return fetch(
    url,
    requestOptions
  )
    .then(handleResponse)
    .then(res => {
      return res;
    });
}