import { HighlightOutlined, UnorderedListOutlined, NotificationOutlined, RiseOutlined, FileOutlined, BankOutlined, ApartmentOutlined, AuditOutlined, RocketOutlined, ProfileOutlined, ProductOutlined, ReadOutlined } from '@ant-design/icons';
export const publicRoutes = [
  {
    path: "/login",
    component: "./pages/Login/Login.react"
  },
  {
    path: "/logout",
    component: "./pages/Login/Login.react"
  },
  {
    path: "/user/confirm/:confirmationToken",
    component: "./pages/Signup/Signup.react"
  },
  {
    path: '/forgot-password',
    component: './pages/Login/ForgotPassword.react'
  },
  {
    path: "/change-password",
    component: "./pages/Login/ChangePassword.react"
  },
  {
    path: "/resource/:resourceType/:resourceId/token/:token/view",
    component: "./components/Share/ShareViewer"
  }
];

export const privateRoutes = [
  {
    path: '/',
    component: './pages/SystemsManager/SystemsManager',
    roles: ['ROLE_ADMIN']
  },
  {
    name: 'PATRIMONIO',
    path: '/ooh',
    icon: <BankOutlined />,
    component: './pages/SystemsManager/SystemsManager',
    roles: ['ROLE_ADMIN'],
    routes: [
      { name: <><BankOutlined /> Impianti</>, path: '/systems-manage/manage', disabled: false },
      // { name: <><PushpinOutlined /> Mappa</>, path: '/systems-manage/map', disabled: false },
      {
        name: <><ApartmentOutlined /> Circuiti</>,
        path: '/circuit-manage/manage',
        component: './pages/CircuitManager/CircuitManager',
        roles: ['ROLE_ADMIN'],
      },
    ]
  },
  {
    name: 'PUBBLICAZIONE',
    path: '/dooh',
    icon: <RocketOutlined />,
    component: './pages/SystemsManager/SystemsManager',
    roles: ['ROLE_ADMIN', 'ROLE_SALES', 'ROLE_SALESLEADER'],
    routes: [
      // { name: <><SolutionOutlined /> Opportunità</>, path: '/opportunities', disabled: true },
      { name: <><FileOutlined /> Preventivi </>, path: '/estimates/manage ', disabled: false },
      { name: <>< ProfileOutlined /> Ordini</>, path: '/orders', disabled: false },
      { name: <><NotificationOutlined /> Campagne </>, path: '/campaigns', disabled: false },
      // { name: <><ClusterOutlined /> Circuiti</>, path: '/circuit-manage/manage', disabled: false },
      // { name: <><SendOutlined /> Pianificazione</>, path: '/planning/new', disabled: true },
    ]
  },
  {
    name: 'INVENTARIO',
    path: '/estimates',
    icon: <ProductOutlined />,
    component: './pages/Estimates/Estimates',
    roles: ['ROLE_ADMIN', 'ROLE_SALES', 'ROLE_SALESLEADER'],
    routes: [
      { name: <><ProductOutlined /> Prodotti </>, path: '/estimates/products', disabled: false },
      { name: <><ReadOutlined /> Listini </>, path: '/estimates/products?catalogs', disabled: false },
      // { name: <><PushpinOutlined /> Mappa</>, path: '/systems-manage/map', disabled: false },
      // { name: <><RiseOutlined /> Analytics</>, path: '/systems-manage/analytics', disabled: true },
    ]
  },
  // {
  //   name: 'ORDINI',
  //   path: '/dooh',
  //   // icon: <FundProjectionScreenOutlined />,
  //   component: './pages/SystemsManager/SystemsManager',
  //   roles: ['ROLE_ADMIN'],
  //   routes: [
  //     { name: <><NotificationOutlined /> Campagne </>, path: '/campaigns', disabled: false },
  //     // { name: <><ClusterOutlined /> Circuiti</>, path: '/circuit-manage/manage', disabled: false },
  //     { name: <><SendOutlined /> Pianificazione</>, path: '/planning/new', disabled: false },
  //     { name: <><PieChartOutlined /> Dashboard</>, path: '/dooh/players', disabled: false },
  //     { name: <><RiseOutlined /> Analytics</>, path: '/dooh/analytics', disabled: false },
  //   ]
  // },
  // {
  //   name: 'PREVENTIVI',
  //   path: '/estimates',
  //   component: './pages/Estimates/Estimates',
  //   roles: ['ROLE_ADMIN'],
  //   routes: [
  //     { name: <><FileOutlined /> Gestione Preventivi </>, path: '/estimates/manage ', disabled: false },
  //     { name: <><ShopOutlined /> Inventario prodotti </>, path: '/estimates/products', disabled: false },
  //   ]
  // },
  {
    name: 'MEDIA MONITOR',
    path: '/mediamonitor',
    icon: <AuditOutlined />,
    component: './pages/MediaMonitor/MediaMonitor',
    roles: ['ROLE_ADMIN', 'ROLE_MEDIAMONITOR'],
    routes: [
      { name: <><AuditOutlined /> Rilevazioni</>, path: '/media/monitor', disabled: false },
      { name: <><RiseOutlined /> Media Monitor</>, path: '/media/analytics', disabled: false },
      { name: <><HighlightOutlined /> Gestione Campi</>, path: '/media/manage', disabled: false },
    ]
  },
  {
    name: 'ANALYTICS',
    path: '/analytics',
    icon: <UnorderedListOutlined />,
    // component: './pages/MediaMonitor/MediaMonitor',
    roles: ['ROLE_ADMIN', 'ROLE_MEDIAMONITOR'],
    routes: [
      { name: <><RiseOutlined /> Dashboard Funnel</>, path: '/dashboard/analytics', disabled: false },
      { name: <><RiseOutlined /> Programmatic DOOH</>, path: '/DOOH/analytics', disabled: false },
      { name: <><RiseOutlined /> Media Monitor</>, path: '/media/analytics', disabled: false },
    ]
  },
  {
    path: '/systems-manage/map',
    component: './pages/SystemsManager/SystemsManager',
    roles: ['ROLE_ADMIN'],
  },
  {
    path: '/systems-manage/manage',
    component: './pages/SystemsManager/SystemsManager',
    roles: ['ROLE_ADMIN'],
  },
  // {
  //   path: '/systems-manage/manage/new',
  //   component: './pages/SystemsManager/SystemFormPage',
  //   roles: ['ROLE_ADMIN'],
  // },
  // {
  //   path: '/systems-manage/manage/edit/:id',
  //   component: './pages/SystemsManager/SystemFormPage',
  //   roles: ['ROLE_ADMIN'],
  // },
  {
    path: '/dooh-manage/manage',
    component: './pages/DoohManager/DoohManager',
    roles: ['ROLE_ADMIN'],
  },
  {
    path: '/circuit-manage/manage',
    component: './pages/CircuitManager/CircuitManager',
    roles: ['ROLE_ADMIN'],
  },

  {
    // name: 'Shows',
    path: '/shows-manage/manage',
    component: './pages/ShowsManager/ShowsManager',
    roles: ['ROLE_ADMIN'],
  },
  {
    // name: 'Circuiti DOOH',
    path: '/dooh/players',
    component: './pages/Dooh/Players',
    roles: ['ROLE_ADMIN'],
  },
  {
    // name: 'Dashboard Analytics',
    path: '/dashboard/analytics',
    component: './pages/Analytics/DashboardAnalytics',
    roles: ['ROLE_ADMIN', 'ROLE_MEDIAMONITOR'],
  },
  {
    // name: 'DOOH Analytics',
    path: '/DOOH/analytics',
    component: './pages/Analytics/DOOHAnalytics',
    roles: ['ROLE_ADMIN', 'ROLE_MEDIAMONITOR'],
  },
  {
    // name: 'Media Analytics',
    path: '/media/analytics',
    component: './pages/Analytics/MediaAnalytics',
    roles: ['ROLE_ADMIN', 'ROLE_MEDIAMONITOR'],
  },
  {
    // name: 'Media Monitor',
    path: '/media/monitor',
    component: './pages/MediaMonitor/MediaMonitor',
    roles: ['ROLE_ADMIN', 'ROLE_MEDIAMONITOR'],
  },
  {
    // name: 'Gestione Campi',
    path: '/media/manage',
    component: './pages/MediaMonitor/MediaMonitorManageFields',
    roles: ['ROLE_ADMIN', 'ROLE_MEDIAMONITOR'],
  },
  {
    // name: 'Campaigns',
    path: '/campaigns',
    component: './pages/Campaigns/Campaign.Manager',
    roles: ['ROLE_ADMIN', 'ROLE_SALES', 'ROLE_SALESLEADER'],
  },
  {
    path: '/campaigns/:id',
    component: './pages/Campaigns/Campaign.Page',
    roles: ['ROLE_ADMIN', 'ROLE_SALES', 'ROLE_SALESLEADER']
  },
  {
    path: '/planning/:id',
    component: './pages/Plannings/Planning.Configurator',
    roles: ['ROLE_ADMIN']
  },

  {
    path: '/estimates/products',
    component: './pages/Estimates/Products',
    roles: ['ROLE_ADMIN', 'ROLE_SALES', 'ROLE_SALESLEADER'],
  },

  {
    path: '/estimates/manage',
    component: './pages/Estimates/Estimates',
    roles: ['ROLE_ADMIN', 'ROLE_SALES', 'ROLE_SALESLEADER'],
  },

  {
    path: '/settings/users',
    component: './pages/Settings/UsersSettings',
    roles: ['ROLE_ADMIN']
  },
  {
    path: "/profile",
    component: "./pages/Profile/Profile.jsx",
    roles: ['ROLE_ADMIN', 'ROLE_GUEST', 'ROLE_PLANNER', 'ROLE_SUPERVISOR', 'ROLE_DESIGNER', 'ROLE_MEDIAMONITOR', 'ROLE_SALES', 'ROLE_SALESLEADER', 'ROLE_ASSETMANAGER']
  },
  {
    path: "/orders",
    component: "./pages/Orders/Orders.jsx",
    roles: ['ROLE_ADMIN', 'ROLE_GUEST', 'ROLE_PLANNER', 'ROLE_SUPERVISOR', 'ROLE_DESIGNER', 'ROLE_MEDIAMONITOR', 'ROLE_SALES', 'ROLE_SALESLEADER', 'ROLE_ASSETMANAGER']
  },
  {
    path: "/orders/orderVisualizer",
    component: "./pages/Orders/OrderVisualizer.jsx",
    roles: ['ROLE_ADMIN', 'ROLE_GUEST', 'ROLE_PLANNER', 'ROLE_SUPERVISOR', 'ROLE_DESIGNER', 'ROLE_MEDIAMONITOR', 'ROLE_SALES', 'ROLE_SALESLEADER', 'ROLE_ASSETMANAGER']
  },
];