import defaultSettings from "./defaultSettings";
import { gatewayConfig } from "./gateway";
import { privateRoutes } from "./routes";

const { REACT_APP_ENV } = process.env;

const {
  GEO_SERVICE,
  USER_SERVICE,
  SYSTEMS_SERVICE,
  CIRCUITS_SERVICE,
  AUDIT_SERVICE,
  CATALOG_SERVICE,
  PRODUCT_SERVICE,
  ESTIMATE_SERVICE,
  CREATIVITY_SERVICE,
  COMPANIES_SERVICE,
  CAMPAIGN_SERVICE,
  PLANNER_SERVICE,
  SHARE_SERVICE,
  ORDER_SERVICE,
  FRAME_SERVICE,
} = gatewayConfig;

if (REACT_APP_ENV) {
  console.log("REACT_APP_ENV", REACT_APP_ENV);
}

let theme = "";

function getTheme(themeName) {
  return themes[themeName] || themes.classic;
}
const themes = {
  classic: {
    primaryColor: "#0a3f8a",
    successColor: "#0A8A15",
    infoColor: "#0A7F8A",
    processingColor: "#BFBFBF",
    warningColor: "#FFC53D",
    // colorPrimaryBg: "#b5c1c9",
    // colorPrimaryBgHover: "#86a4bd",
    // colorPrimaryBorder: "#618ab0",
    // colorPrimaryBorderHover: "#4070a3",
    // colorPrimaryHover: "#235796",
    // colorPrimary: "#0a3f8a",
    // colorPrimaryActive: "#022763",
    // colorPrimaryTextHover: "#235796",
    // colorPrimaryText: "#0a3f8a",
    // colorPrimaryTextActive: "#022763",
    // colorTextBase: "#FFFFFF",
    // colorBgBase: "#000000",
    token: {
      colorPrimaryBg: "#b5c1c9",
      colorPrimaryBgHover: "#86a4bd",
      colorPrimaryBorder: "#618ab0",
      colorPrimaryBorderHover: "#4070a3",
      colorPrimaryHover: "#235796",
      colorPrimary: "#0a3f8a",
      colorPrimaryActive: "#022763",
      colorPrimaryTextHover: "#235796",
      colorPrimaryText: "#0a3f8a",
      colorPrimaryTextActive: "#022763",
      colorTextBase: "#000000",
      colorBgBase: "#FFFFFF",
    },
  },
  dark: {
    token: {
      colorPrimaryBg: "#11141a",
      colorPrimaryBgHover: "#101928",
      colorPrimaryBorder: "#112137",
      colorPrimaryBorderHover: "#102749",
      colorPrimaryHover: "#225089",
      colorPrimary: "#0c3978",
      colorPrimaryActive: "#0e3061",
      colorPrimaryTextHover: "#225089",
      colorPrimaryText: "#0c3978",
      colorPrimaryTextActive: "#0e3061",
      colorTextBase: "#FFFFFF",
      colorBgBase: "#000000",
      colorBgContainer: "#181515",
      colorBgElevated: "#181515",
      bodyBg: "181515",
      contentBg: "#000000",
      colorText: "#FFFFFF",
    },
  },
};

if (localStorage.getItem("user")) {
  let user = localStorage.getItem("user");
  user = JSON.parse(user);

  const selectedTheme = user.principal.preference ? user.principal.preference.theme : "classic";

  theme = getTheme(selectedTheme);
}

// const theme = { ORIGINAL COLORS
//   primaryColor: '#0a3f8a',
//   // primaryColor: '#02418B',
//   successColor: "#0A8A15",
//   infoColor: "#0A7F8A",
//   processingColor: "#BFBFBF",
//   // errorColor: "#8A0A3F",
//   warningColor: "#FFC53D",
//   }

export const defineConfig = {
  ...defaultSettings,
  theme: {...theme},
  sidebar: true,
  footer: false,
  routes: privateRoutes,
  AUDIT_SERVICE: AUDIT_SERVICE(REACT_APP_ENV || "prod"),
  GEO_SERVICE: GEO_SERVICE(REACT_APP_ENV || "prod"),
  USER_SERVICE: USER_SERVICE(REACT_APP_ENV || "prod"),
  SYSTEMS_SERVICE: SYSTEMS_SERVICE(REACT_APP_ENV || "prod"),
  CIRCUITS_SERVICE: CIRCUITS_SERVICE(REACT_APP_ENV || "prod"),
  CREATIVITY_SERVICE: CREATIVITY_SERVICE(REACT_APP_ENV || "prod"),
  COMPANIES_SERVICE: COMPANIES_SERVICE(REACT_APP_ENV || "prod"),
  CAMPAIGN_SERVICE: CAMPAIGN_SERVICE(REACT_APP_ENV || "prod"),
  PLANNER_SERVICE: PLANNER_SERVICE(REACT_APP_ENV || "prod"),
  CATALOG_SERVICE: CATALOG_SERVICE(REACT_APP_ENV || "prod"),
  PRODUCT_SERVICE: PRODUCT_SERVICE(REACT_APP_ENV || "prod"),
  ESTIMATE_SERVICE: ESTIMATE_SERVICE(REACT_APP_ENV || "prod"),
  SHARE_SERVICE: SHARE_SERVICE(REACT_APP_ENV || 'prod'),
  ORDER_SERVICE: ORDER_SERVICE(REACT_APP_ENV || 'prod'),
  FRAME_SERVICE: FRAME_SERVICE(REACT_APP_ENV || 'prod'),
};
